const rootURL = process.env.NODE_ENV=="production" ? `https://intec.keycloak.petricore.systems` : "https://test.keycloak.petricore.systems";

function ToRealm(realm, endpoint, method, body, headers) {
    if (headers == null) {
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    if (body == null) {
        body = ""
    } else {
        var b = ""
        for (var i=0; i < body.length; i++) {
            b = b + body[i][0] + "=" + body[i][1]
            if (i < body.length - 1) {
                b = b + "&"
            }
        }
        body = b
    }
    
    var call = {
        method: method,
        //mode: 'no-cors',
        headers: headers
    }
    if (body != "") {
        call.body = body
    }

    return new Promise(
        function(resolve, reject) {
            fetch(`${rootURL}/auth/realms/${realm}/${endpoint}`, call)
            .then(function (res) {
                if (res.ok) {
                    res.json().then((json) => {
                        resolve(json)
                    }).catch((res) => {
                        resolve(null)
                    });
                } else {
                    console.error(res);
                    reject(res)
                }
            })
            .catch(function (error) {
                console.error(error);
                reject(error)
            })
        }
    )
}

function ToAdminConsole(realm, endpoint, method, body, headers) {
    if (headers == null) {
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    if (body == null) {
        body = ""
    } else {
        var b = ""
        for (var i=0; i < body.length; i++) {
            b = b + body[i][0] + "=" + body[i][1]
            if (i < body.length - 1) {
                b = b + "&"
            }
        }
        body = b
    }

    var call = {
        method: method,
        //mode: 'no-cors',
        headers: headers
    }
    if (body != "") {
        call.body = body
    }

    return new Promise(
        function(resolve, reject) {
            fetch(`${rootURL}/auth/admin/realms/${realm}/${endpoint}`, call)
            .then(function (res) {
                if (res.ok) {
                    res.json().then((json) => {
                        resolve(json)
                    });
                } else {
                    console.error(res);
                    reject(res)
                }
            })
            .catch(function (error) {
                console.error(error);
                reject(error)
            })
        }
    )
}

export default {
    ToRealm,
    ToAdminConsole,
    rootURL
}