import React from 'react';
import PlusButton from '../../Plus-Button.png';
import DeleteButton from '../../Delete-Button.png';
import { Multiselect, DateTimePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';
import UserService from './Keycloak/UserService'
import axios from 'axios';

const FileUploader = props => {     
  const hiddenFileInput = React.useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
    event.target.value = '';
  };
  return (
    <>
      <input type="file"
        className="input-field"
        ref={hiddenFileInput}
        onChange={handleChange}        
        style={{ display: 'none' }}
        accept={props.image? "image/*" : ".pdf"}
      />
      <button style={{marginLeft: props.thin? "392px" : "449px"}} type="button" className="upload-button" onClick={handleClick}>
        Datei suchen
      </button>
    </>
  );
};

class ImageNamepair
{
  constructor() {
    this.file = null;
    this.name = '';
    this.src = '';
  }
}

class Baseform extends React.Component {
  

  handleChange(event, parameter) {            
    
    event.persist();
    this.setState({
      [parameter]: event.target.value,
    });
    this.instantValidation(event.target);
  }
  
  handleTagsChange(event, parameter) {        
    event.persist();
    this.setState({
      [parameter]: event.target.value,
    });
    this.instantValidation(event.target);
  }  

  handleDateChange(value,index) {            
    this.state.sessions[index].sessiondate= value;          
    this.forceUpdate();
  }

  handleSessionNameChange(event,index) {                
    event.persist();
    
    this.state.sessions[index].sessionname = event.target.value;    
    this.forceUpdate();
    this.instantValidation(event.target);
  }

  handleSessionPersonChange(field,index, pIndex) {                
    if(pIndex == 0)
    {
      this.state.sessions[index].person1 = field.value;
    }
    else if(pIndex == 1)
    {
      this.state.sessions[index].person2 = field.value;
    }
    else if(pIndex == 2)
    {
      this.state.sessions[index].person3 = field.value;
    }
    else if(pIndex == 3)
    {
      this.state.sessions[index].person4 = field.value;
    }
    else if(pIndex == 4)
    {
      this.state.sessions[index].person5 = field.value;
    }
    this.forceUpdate();
    this.instantValidation(field);
  }

  showInvalidImageAlert(width, height) {
    alert("Das ausgewählte Bild hat unpassende Maße. Bitte laden Sie ein Bild mit einer Breite von " + width + " und einer Höhe von " + height + " Pixeln hoch.");
  }

  showInvalidRatioAlert(width, ratio) {
    alert("Das ausgewählte Bild hat unpassende Maße. Bitte laden Sie ein Bild mit einer Breite von mindestens " + width + " Pixeln und in " + ratio + " hoch.");
  }

  showInvalidFormat(format)
  {
    alert("Die ausgewählte Datei hat das falsche Dateiformat. Es wird eine " + format + " Datei erwartet.");
  }

  showInvalidSize()
  {
    alert("Die ausgewählte Datei ist zu groß.");
  }

  handleProductImageUpload(file, id) {    
    if (file == null) return;    
    var fileextension = file.name.split('.').pop().toLowerCase();
    if (fileextension != 'bmp' && fileextension != 'gif' &&fileextension != 'jpg' && fileextension != 'tiff' && fileextension != 'tif' && fileextension != 'png' && fileextension != 'jpeg')
    {
      this.showInvalidFormat('bmp, gif, tiff, png, jpg oder jpeg');
      return;
    }
    else if (file.size > 5000000)
    {
      this.showInvalidSize();
      return;
    }
    const self = this;
    const filenamepair = new ImageNamepair();
    filenamepair.file = file;
    filenamepair.name = file.name;
    var img = new Image();        
    img.src = window.URL.createObjectURL( file );    
    filenamepair.src = img.src;
        img.onload = function() 
        {          
            var width = this.naturalWidth,
                height = this.naturalHeight;
          if (width < 500 || width < height) {
            self.showInvalidRatioAlert(500, "Querformat");
            return;
          }
          else
          {
            self.setState({
              [id]: filenamepair
            });
          }
        };        
  }

  handleLogoUpload(file, id) {
    if (file == null) return;
    var fileextension = file.name.split('.').pop().toLowerCase();
    if (fileextension != 'jpg' && fileextension != 'gif' && fileextension != 'png' && fileextension != 'jpeg')
    {
      this.showInvalidFormat('jpg, gif, png oder jpeg');
      return;
    }
    else if (file.size > 2000000)
    {
      this.showInvalidSize();
      return;
    }
    const self = this;
    var img = new Image();
    img.src = window.URL.createObjectURL( file );
        img.onload = function() 
        {          
            var width = this.naturalWidth,
                height = this.naturalHeight;
          if (width != 1080 || height != 1080) {
            self.showInvalidImageAlert(1080, 1080);
            return;
          }
          else
          {
            let filenamepair = new ImageNamepair();
            filenamepair.file = file;
            filenamepair.name = file.name;
            self.setState({
              
              [id]: filenamepair
            });
          }
        };
  }

  handleBackgroundUpload(file, id) {
    if (file == null) return;
    var fileextension = file.name.split('.').pop().toLowerCase();    
    if (fileextension != 'jpg' && fileextension != 'gif' && fileextension != 'png' && fileextension != 'jpeg')
    {
      this.showInvalidFormat('jpg, gif, png oder jpeg');
      return;
    }
    else if (file.size > 2000000)
    {
      this.showInvalidSize();
      return;
    }
    const self = this;
    var img = new Image();
    img.src = window.URL.createObjectURL( file );
        img.onload = function() 
        {          
            var width = this.naturalWidth,
                height = this.naturalHeight;
          if (self.state.paket == 'basic' && (width != 1500 || height != 1000)) {
            self.showInvalidImageAlert(1500, 1000);
            return;
          }
          else if (self.state.paket == 'comfort' && (width != 1500 || height != 750)) {
            self.showInvalidImageAlert(1500, 750);
            return;
          }
          else  if (self.state.paket == 'premium' && (width != 2000 || height != 500)) {
            self.showInvalidImageAlert(2000, 500);
            return;
          }
          else
          {
            let filenamepair = new ImageNamepair();
            filenamepair.file = file;
            filenamepair.name = file.name;
            self.setState({
              [id]: filenamepair
            });
          }
        };
  }

  handlePdfFileUpload(file, id)
  {
    if (file == null) return;
    var fileextension = file.name.split('.').pop().toLowerCase();
    if (fileextension != 'pdf')
    {
      this.showInvalidFormat('pdf');
      return;
    }
    else if (file.size > 5000000)
    {
      this.showInvalidSize();
      return;
    }
    let filenamepair = new ImageNamepair();
    filenamepair.file = file;
    filenamepair.name = file.name;
    this.setState({      
      [id]: filenamepair
      
    });    
  }

  addSession(event)
  {    
    var session = {
      sessionname : '', 
      sessiondate : new Date(2021, 2, 2,10,0,0),  
      person1 : '',
      person2 : '',
      person3 : '',
      person4 : '',
      person5 : '',
    };
    this.state.sessions.push(session);
    this.forceUpdate();    
  }

  getInitialInformation()
  {
    const self = this;
    //let url = 'https://upload.messe-intec.de/uploadapi.php?';
    let url = 'http://cdn.exhib.io/test2/uploadapi.php?';
      axios.get(url+'ordner='+this.state.firma+'&task=companyInfo',
      {

      })
        .then(function (response) {                        
            self.state.companyName = response.data['companyname'];
            self.state.companyEmail = response.data['email'];
            self.state.companyAdress = response.data['companyAdress'];
            self.state.companyPostal = response.data['companyPostal'];
            self.state.companyCity = response.data['companyCity'];
            if (response.data['companyWebsite'] !== "undefined")
              self.state.companyWebsite = response.data['companyWebsite'];
            if (response.data['companySlogan'] !== "undefined")
              self.state.companySlogan = response.data['companySlogan'];
            if (response.data['companyInfo'] !== "undefined")
              self.state.companyInfo = response.data['companyInfo'];
            if (response.data['googleSlidesURL'] !== "undefined")
              self.state.googleSlidesURL = response.data['googleSlidesURL'];
            if (response.data['companySocialMediaURL1'] !== "undefined")
              self.state.companySocialMediaURL1 = response.data['companySocialMediaURL1'];
            if (response.data['companySocialMediaURL2'] !== "undefined")
              self.state.companySocialMediaURL2 = response.data['companySocialMediaURL2'];
            if (response.data['companySocialMediaURL3'] !== "undefined")
              self.state.companySocialMediaURL3 = response.data['companySocialMediaURL3'];
            if (typeof response.data['images'] !== "undefined")
            {
              self.state.companyLogo.name = response.data['images']['images'][0];            
              self.state.backgroundImage.name = response.data['images']['images'][1];
            }            
            self.forceUpdate();
        })
        .catch(function (response) {
            console.log(response);
      });

      axios.get(url+'ordner='+this.state.firma+'&task=tags',
      {

      })
        .then(function (response) {                    
          if (typeof response.data['tags'] !== "undefined")
          {
            if (response.data['tags'][0] !== "undefined" && response.data['tags'][0] !== "")
              self.state.tags = response.data['tags'];
          }
        })
        .catch(function (response) {
            console.log(response);
      });
      axios.get(url+'ordner='+this.state.firma+'&task=products',
      {

      })
        .then(function (response) {
            if (typeof response.data['productinfo'] !== "undefined")
            {
              let productinfo = response.data['productinfo']['companyinfo'];              
              self.state.additionalCompanyInfo1 = productinfo[0];
              self.state.additionalCompanyInfo2 = productinfo[1];
              self.state.additionalCompanyInfo3 = productinfo[2];
              self.state.additionalCompanyInfo4 = productinfo[3];
              self.state.additionalCompanyInfo5 = productinfo[4];
              self.state.additionalCompanyInfo6 = productinfo[5];
              self.state.additionalCompanyInfo7 = productinfo[6];
            }
            
            if (typeof response.data['productimages'] !== "undefined")              
            {
              self.state.productImage1.name = response.data['productimages']['productimages'][0];
              self.state.productImage2.name = response.data['productimages']['productimages'][1];
              self.state.productImage3.name = response.data['productimages']['productimages'][2];
              self.state.productImage4.name = response.data['productimages']['productimages'][3];
              self.state.productImage5.name = response.data['productimages']['productimages'][4];
              self.state.productImage6.name = response.data['productimages']['productimages'][5];
              self.state.productImage7.name = response.data['productimages']['productimages'][6];
            }
            self.forceUpdate();
        })
        .catch(function (response) {
            console.log(response);
      });

      axios.get(url+'ordner='+this.state.firma+'&task=pdfs',
      {

      })
        .then(function (response) {            
            if (typeof response.data['pdfs'] !== "undefined")
            {
              self.state.downloadDbPdf1.name = response.data['pdfs']['pdfs'][0];
              self.state.downloadDbPdf2.name = response.data['pdfs']['pdfs'][1];
              self.state.downloadDbPdf3.name = response.data['pdfs']['pdfs'][2];
              self.state.downloadDbPdf4.name = response.data['pdfs']['pdfs'][3];
              self.state.downloadDbPdf5.name = response.data['pdfs']['pdfs'][4];
              self.state.downloadDbPdf6.name = response.data['pdfs']['pdfs'][5];
              self.state.downloadDbPdf7.name = response.data['pdfs']['pdfs'][6];
              self.state.downloadDbPdf8.name = response.data['pdfs']['pdfs'][7];
              self.state.downloadDbPdf9.name = response.data['pdfs']['pdfs'][8];
              self.state.downloadDbPdf10.name = response.data['pdfs']['pdfs'][9];
              self.state.downloadDbPdf11.name = response.data['pdfs']['pdfs'][10];
              self.state.downloadDbPdf12.name = response.data['pdfs']['pdfs'][11];
              self.state.downloadDbPdf13.name = response.data['pdfs']['pdfs'][12];
              self.state.downloadDbPdf14.name = response.data['pdfs']['pdfs'][13];
              self.state.downloadDbPdf15.name = response.data['pdfs']['pdfs'][14];
            }
            self.forceUpdate();
          })
          .catch(function (response) {
              console.log(response);
        });

      axios.get(url+'ordner='+this.state.firma+'&task=sessions',
      {

      })
        .then(function (response) {
            if (typeof response.data['sessions'] !== "undefined")
            {
              for (let i = 0; i < response.data['sessions'].length; i++)
              {
              
                var session = {
                  sessionname :  response.data['sessions'][i].sessionname,
                  sessiondate : new Date(Date.parse(response.data['sessions'][i].date)),
                  
                  person1 : response.data['sessions'][i].person1,
                  person2 : response.data['sessions'][i].person2,
                  person3 : response.data['sessions'][i].person3,
                  person4 : response.data['sessions'][i].person4,
                  person5 : response.data['sessions'][i].person5
                };                
                self.state.sessions.push(session);
              }
              self.forceUpdate();
            }
          })
          .catch(function (response) {
              console.log(response);
        });
  }

  handleUploadSubmit = event => {
    event.preventDefault();
    const self = this;
    if (!window.confirm("Wollen Sie ihre Daten jetzt einreichen?"))
    {
      return;
    }    

    this.state.uploading=true;
    this.forceUpdate();
    let bodyFormDataInfo = new FormData();
    let bodyFormDataDb = new FormData();
    let bodyFormDataProductInfo = new FormData();
    let bodyFormDataImages = new FormData();    
    let bodyFormDataTags = new FormData();

      //companyinfo
      bodyFormDataInfo.append('task', 'companyinfo'); 
      bodyFormDataInfo.append('ordner', this.state.firma); 
      bodyFormDataInfo.append('companyname', this.state.companyName); 
      bodyFormDataInfo.append('email', this.state.companyEmail);
      bodyFormDataInfo.append('companyAdress', this.state.companyAdress);
      bodyFormDataInfo.append('companyPostal', this.state.companyPostal);
      bodyFormDataInfo.append('companyCity', this.state.companyCity);
      bodyFormDataInfo.append('companyWebsite', this.state.companyWebsite);
      bodyFormDataInfo.append('companySlogan', this.state.companySlogan);
      bodyFormDataInfo.append('companyInfo', this.state.companyInfo);
      bodyFormDataInfo.append('googleSlidesURL', this.state.googleSlidesURL);
      bodyFormDataInfo.append('companySocialMediaURL1', this.state.companySocialMediaURL1);      
      bodyFormDataInfo.append('companySocialMediaURL2', this.state.companySocialMediaURL2);
      bodyFormDataInfo.append('companySocialMediaURL3', this.state.companySocialMediaURL3);
      bodyFormDataInfo.append('companyLogo', this.state.companyLogo.file);
      bodyFormDataInfo.append('backgroundImage', this.state.backgroundImage.file);
      bodyFormDataInfo.append('companyLogoname', this.state.companyLogo.name);
      bodyFormDataInfo.append('backgroundImagename', this.state.backgroundImage.name);
      //downloaddb
      bodyFormDataDb.append('task', 'downloaddb'); 
      bodyFormDataDb.append('ordner', this.state.firma); 
      bodyFormDataDb.append('downloadDbPdf1', this.state.downloadDbPdf1.file);
      bodyFormDataDb.append('downloadDbPdf2', this.state.downloadDbPdf2.file);
      bodyFormDataDb.append('downloadDbPdf3', this.state.downloadDbPdf3.file);
      bodyFormDataDb.append('downloadDbPdf4', this.state.downloadDbPdf4.file);
      bodyFormDataDb.append('downloadDbPdf5', this.state.downloadDbPdf5.file);
      bodyFormDataDb.append('downloadDbPdf6', this.state.downloadDbPdf6.file);
      bodyFormDataDb.append('downloadDbPdf7', this.state.downloadDbPdf7.file);
      bodyFormDataDb.append('downloadDbPdf8', this.state.downloadDbPdf8.file);
      bodyFormDataDb.append('downloadDbPdf9', this.state.downloadDbPdf9.file);
      bodyFormDataDb.append('downloadDbPdf10', this.state.downloadDbPdf10.file);
      bodyFormDataDb.append('downloadDbPdf11', this.state.downloadDbPdf11.file);
      bodyFormDataDb.append('downloadDbPdf12', this.state.downloadDbPdf12.file);
      bodyFormDataDb.append('downloadDbPdf13', this.state.downloadDbPdf13.file);
      bodyFormDataDb.append('downloadDbPdf14', this.state.downloadDbPdf14.file);
      bodyFormDataDb.append('downloadDbPdf15', this.state.downloadDbPdf15.file);
      bodyFormDataDb.append('downloadDbPdf1name', this.state.downloadDbPdf1.name);
      bodyFormDataDb.append('downloadDbPdf2name', this.state.downloadDbPdf2.name);
      bodyFormDataDb.append('downloadDbPdf3name', this.state.downloadDbPdf3.name);
      bodyFormDataDb.append('downloadDbPdf4name', this.state.downloadDbPdf4.name);
      bodyFormDataDb.append('downloadDbPdf5name', this.state.downloadDbPdf5.name);
      bodyFormDataDb.append('downloadDbPdf6name', this.state.downloadDbPdf6.name);
      bodyFormDataDb.append('downloadDbPdf7name', this.state.downloadDbPdf7.name);
      bodyFormDataDb.append('downloadDbPdf8name', this.state.downloadDbPdf8.name);
      bodyFormDataDb.append('downloadDbPdf9name', this.state.downloadDbPdf9.name);
      bodyFormDataDb.append('downloadDbPdf10name', this.state.downloadDbPdf10.name);
      bodyFormDataDb.append('downloadDbPdf11name', this.state.downloadDbPdf11.name);
      bodyFormDataDb.append('downloadDbPdf12name', this.state.downloadDbPdf12.name);
      bodyFormDataDb.append('downloadDbPdf13name', this.state.downloadDbPdf13.name);
      bodyFormDataDb.append('downloadDbPdf14name', this.state.downloadDbPdf14.name);
      bodyFormDataDb.append('downloadDbPdf15name', this.state.downloadDbPdf15.name);
      //productinfo
      bodyFormDataProductInfo.append('task', 'productinfo'); 
      bodyFormDataProductInfo.append('ordner', this.state.firma); 
      bodyFormDataProductInfo.append('additionalCompanyInfo1', this.state.additionalCompanyInfo1);
      bodyFormDataProductInfo.append('additionalCompanyInfo2', this.state.additionalCompanyInfo2);
      bodyFormDataProductInfo.append('additionalCompanyInfo3', this.state.additionalCompanyInfo3);
      bodyFormDataProductInfo.append('additionalCompanyInfo4', this.state.additionalCompanyInfo4);
      bodyFormDataProductInfo.append('additionalCompanyInfo5', this.state.additionalCompanyInfo5);
      bodyFormDataProductInfo.append('additionalCompanyInfo6', this.state.additionalCompanyInfo6);
      bodyFormDataProductInfo.append('additionalCompanyInfo7', this.state.additionalCompanyInfo7);
      //poductimages
      bodyFormDataImages.append('task', 'poductimages'); 
      bodyFormDataImages.append('ordner', this.state.firma); 
      bodyFormDataImages.append('productImage1', this.state.productImage1.file);
      bodyFormDataImages.append('productImage2', this.state.productImage2.file);
      bodyFormDataImages.append('productImage3', this.state.productImage3.file);
      bodyFormDataImages.append('productImage4', this.state.productImage4.file);
      bodyFormDataImages.append('productImage5', this.state.productImage5.file);
      bodyFormDataImages.append('productImage6', this.state.productImage6.file);
      bodyFormDataImages.append('productImage7', this.state.productImage7.file);
      bodyFormDataImages.append('productImage1name', this.state.productImage1.name);
      bodyFormDataImages.append('productImage2name', this.state.productImage2.name);
      bodyFormDataImages.append('productImage3name', this.state.productImage3.name);
      bodyFormDataImages.append('productImage4name', this.state.productImage4.name);
      bodyFormDataImages.append('productImage5name', this.state.productImage5.name);
      bodyFormDataImages.append('productImage6name', this.state.productImage6.name);
      bodyFormDataImages.append('productImage7name', this.state.productImage7.name);          
      //tags
      bodyFormDataTags.append('task', 'tags'); 
      bodyFormDataTags.append('ordner', this.state.firma); 
      bodyFormDataTags.append('tags', this.state.tags);      

      //let url = 'https://upload.messe-intec.de/uploadapi.php';
      let url = 'http://cdn.exhib.io/test2/uploadapi.php';
      axios.post(url, bodyFormDataInfo,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
            //handle success                        
            console.log(response);
            self.state.companyInfoUploaded=true;
            self.checkIfUploadFinished();
        })
        .catch(function (response) {
            //handle error
            
            self.state.companyInfoUploaded=true;
            self.checkIfUploadFinished();      
            console.log(response);
      });
      
      axios.post(url, bodyFormDataProductInfo,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
            //handle success            
            self.state.productInfoUploaded=true;
            self.checkIfUploadFinished();      
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            self.state.productInfoUploaded=true;
            self.checkIfUploadFinished();    
      });      
      axios.post(url, bodyFormDataDb,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
            //handle success
            self.state.dbUploaded= true;
            self.checkIfUploadFinished();              
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            self.state.dbUploaded= true;
            self.checkIfUploadFinished();              
      });
      axios.post(url, bodyFormDataImages,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
            //handle success
            self.state.imagesUploaded= true;
            self.checkIfUploadFinished();                
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            self.state.imagesUploaded= true;
            self.checkIfUploadFinished();        
      });
      axios.post(url, bodyFormDataTags,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
            //handle success            
            self.state.tagsUploadeded=true;
            self.checkIfUploadFinished(); 
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            self.state.tagsUploadeded=true;
            self.checkIfUploadFinished(); 
      });
      
      this.sendSessions(0, url);
  }

  sendSessions(index, url)
  {
    const self = this;
    if (index >= this.state.sessions.length)
    {
      return;
    }
      let bodyFormDataSessions = new FormData();
      bodyFormDataSessions.append('task', 'session'); 
      bodyFormDataSessions.append('sessionid', index+1);
      bodyFormDataSessions.append('ordner', this.state.firma); 
      bodyFormDataSessions.append('sessionname', this.state.sessions[index].sessionname);       
      bodyFormDataSessions.append('sessiondate', this.state.sessions[index].sessiondate.toString());      
      bodyFormDataSessions.append('person1', this.state.sessions[index].person1);
      bodyFormDataSessions.append('person2', this.state.sessions[index].person2);
      bodyFormDataSessions.append('person3', this.state.sessions[index].person3);
      bodyFormDataSessions.append('person4', this.state.sessions[index].person4);
      bodyFormDataSessions.append('person5', this.state.sessions[index].person5);
      axios.post(url, bodyFormDataSessions,
      {
        headers: {
          'Content-Type': 'multipart/form-data;charset=utf-8'
        }
      })
      .then(function (response) {
        //handle success
        self.sendSessions(index+1, url)        
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  checkIfUploadFinished()
  {    
    if (this.state.companyInfoUploaded && this.state.dbUploaded && this.state.productInfoUploaded && this.state.imagesUploaded && this.state.tagsUploadeded)
      this.state.uploaded = true;
    this.forceUpdate();
  }

  remSession(index)
  {
    this.state.sessions.splice(index,1);
    this.forceUpdate();
  }

  remFile(parameter, namepair)
  {        
    if(namepair != null)
    {      
      window.URL.revokeObjectURL(namepair.src);
    }                
    let imagepair = new ImageNamepair();
    this.setState({      
      [parameter]: imagepair
    });
    this.forceUpdate();
  }

  instantValidation(field) {
       var invalid =
        (field.getAttribute("required") && !field.value) ||
        (field.getAttribute("pattern") &&
          field.value &&
          !new RegExp(field.getAttribute("pattern")).test(field.value));
      if (!field.value && !field.getAttribute("required"))
          invalid = false
      if (invalid || invalid === "") 
      {
        field.style.background = "#ffaaaa"
        field.style.borderColor = "red"
      }      
      else {
        field.style.background = "lightgrey"
        field.style.borderColor = "black"
      }
      field.reportValidity();
  }

  emptyfunction()
  {}

  handleSubmit(event) {
    event.preventDefault();

    if (!this.loggedIn) {
      this.loggedIn = true;
      const username = this.state.username;
      const password = this.state.password;

          this.setState({ show: false });
          this.props.onLogin(username, password).then((res) => {
              this.props.onStart(username);
          }).catch((err) => {
              this.setState({failedCheck: true})
              this.loggedIn = false
              console.log(err)
          });
      }
  }

  constructor(props) {
    super(props);
    this.addSession = this.addSession.bind(this);
    this.sendSessions = this.sendSessions.bind(this);    
    this.checkIfUploadFinished = this.checkIfUploadFinished.bind(this);
    this.state = {
      paket: '',
      firma: '',
      uploading: false,
      uploaded: false,
      companyInfoUploaded:false,
      dbUploaded: false,
      productInfoUploaded: false,
      imagesUploaded: false,
      tagsUploadeded: false,
      companyName: '',
      companyEmail: '',
      companyAdress: '',
      companyPostal: '',
      companyCity: '',
      companyLogo: new ImageNamepair(),
      companyWebsite: '',
      companySlogan: '',
      companyInfo: '',
      googleSlidesURL: '',
      companySocialMediaURL1: '',
      companySocialMediaURL2: '',
      companySocialMediaURL3: '',    
      sessions: [],
      tags: [],      

      backgroundImage: new ImageNamepair(),

      additionalCompanyInfo1: '',
      additionalCompanyInfo2: '',
      additionalCompanyInfo3: '',
      additionalCompanyInfo4: '',
      additionalCompanyInfo5: '',
      additionalCompanyInfo6: '',
      additionalCompanyInfo7: '',

      downloadDbPdf1: new ImageNamepair(),
      downloadDbPdf2: new ImageNamepair(),
      downloadDbPdf3: new ImageNamepair(),
      downloadDbPdf4: new ImageNamepair(),
      downloadDbPdf5: new ImageNamepair(),
      downloadDbPdf6: new ImageNamepair(),
      downloadDbPdf7: new ImageNamepair(),
      downloadDbPdf8: new ImageNamepair(),
      downloadDbPdf9: new ImageNamepair(),
      downloadDbPdf10: new ImageNamepair(),
      downloadDbPdf11: new ImageNamepair(),
      downloadDbPdf12: new ImageNamepair(),
      downloadDbPdf13: new ImageNamepair(),
      downloadDbPdf14: new ImageNamepair(),
      downloadDbPdf15: new ImageNamepair(),

      productImage1: new ImageNamepair(),
      productImage2: new ImageNamepair(),
      productImage3: new ImageNamepair(),
      productImage4: new ImageNamepair(),
      productImage5: new ImageNamepair(),
      productImage6: new ImageNamepair(),
      productImage7: new ImageNamepair(),

      synced: false,
      
      failedToVerifyPackage: false,
    };
    const self = this;
    UserService.AdminData.getCurrentUser().then((user) => {
      const paket = UserService.AdminData.getAttribute(user, 'paket')
      if (paket != null) {
        const p = paket[0].toLowerCase();
        self.state.paket = p;
        if (p != "premium" && p != "basic" && p != "comfort") {
          self.state.failedToVerifyPackage = true;
        }
      } else {
        self.state.failedToVerifyPackage = true;
      }
      this.forceUpdate()
    });
    UserService.AdminData.getCurrentUser().then((user) => {
      const firma = UserService.AdminData.getAttribute(user, 'firma')
      if (firma != null) {
        const p = firma[0].toLowerCase();
        self.state.firma = p;                
      }
      else
      {
        self.state.failedToVerifyPackage = true;      
      }
      this.forceUpdate()
    });
  }

  render() {
    if (this.state.synced == false && this.state.firma != '')
    {
      this.getInitialInformation();
      this.state.synced = true;
    }
    if (this.state.failedToVerifyPackage) {
      return (
      <div className="App-content" style={{minHeight: '60vh'}}>
      <div className="headlineupload">
      <br></br>
      Etwas ist schief gelaufen.
      <br></br>
      Bite kontakieren sie unseren Support:
        <button className="url" type="button" onClick={()=> window.open("mailto:intec-z-connect@fairnet.de")}>              
              Support kontakieren
            </button>
      </div>
      </div>
      )
    }
    else if (this.state.uploading && this.state.uploaded == false) {
      return (
        <div className="App-content" style={{minHeight: '60vh'}}>
          <div className="headlineupload">
          <br></br>
          uploading...<br></br>Bitte schließen sie dieses Fenster nicht.</div>
          </div>
      )
    }
    else if (this.state.uploaded)
    {
      return (<div className="App-content" style={{minHeight: '60vh'}}>
        <div className="headlineupload">
        <br></br>
          Vielen Dank für ihren Upload<br></br>Sie können dieses Fenster jetzt schließen.
          </div>
          </div>)
    }
  else
  {
    return (      
      <form className="App-content" onSubmit={this.handleUploadSubmit}>
        <div className="headline"><b>Daten des Unternehmens</b></div>
        <div className="column-container">
          <div className="column">
            <div className="text-field">
              <div className="text-field-headline">Firmenname</div>
              <input
                pattern=".{1,255}"                
                type="text"                
                placeholder="Firmenname"
                validationMessage="Dieses Feld darf nicht leer sein."
                className="input-field-wobutton"
                value={this.state.companyName}                
                onChange={(e) => this.handleChange(e, 'companyName')}
                required                                                
              />
            </div>
            <div className="text-field">
              <div className="text-field-headline">Kontakt Email</div>
              <input
                pattern=".{1,255}"                
                type="text"
                placeholder="EMail"
                className="input-field-wobutton"
                value={this.state.companyEmail}
                onChange={(e) => this.handleChange(e, 'companyEmail')}
                required
              />
            </div>
            <div className="text-field">
              <div className="text-field-headline">Anschrift</div>
              <input
                pattern=".{1,255}"                
                type="text"
                placeholder="Straße und Hausnr."
                className="input-field-wobutton"
                value={this.state.companyAdress}
                onChange={(e) => this.handleChange(e, 'companyAdress')}
                required
              />
            </div>
            <div className="address-input">
              <div className="posttext-field">
                <div className="text-field-headline">PLZ</div>
                <input
                  pattern="([0-9]){4,5}"
                  onInput={(e) => this.instantValidation(e.target)}
                  type="text"
                  placeholder="PLZ"
                  className="input-field-wobutton"
                  validationMessage="Geben sie eine gültige Postleitzahl an."
                  value={this.state.companyPostal}
                  onChange={(e) => this.handleChange(e, 'companyPostal')}
                  required
                />
              </div>
              <div className="citytext-field">
                <div className="text-field-headline">Stadt</div>
                <input
                  pattern=".{1,255}"
                  type="text"
                  placeholder="Stadt"
                  className="input-field-wobutton"
                  value={this.state.companyCity}
                  onChange={(e) => this.handleChange(e, 'companyCity')}
                  required
                />
              </div>
            </div>

          </div>
          <div  style={{padding: '30px 0px 0px 0px'}}  className="column">
            <div className="text-field">
              <div className="text-field-headline">
                <dfn className="tooltip">Logo
                <span role="tooltip">Logo: <br></br>1080x1080px<br></br>1:1 max. 2MBfreigestellt<br></br>Dateityp: png, jpg, jpeg, gif</span>
                </dfn>
              </div>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input readOnly type="text" className="input-field" value={this.state.companyLogo ? this.state.companyLogo.name : "" }></input>
                <FileUploader image={true} handleFile={(f) => this.handleLogoUpload(f, "companyLogo")}></FileUploader>              
              </div>
            </div>
            <div className="text-field">                  
        <div className="text-field-headline">
          <dfn className="tooltip"  style={{display:this.state.paket!='basic'?'none':'block'}}>Banner/Background Motiv
            <span role="tooltip">1500x1000px; 3:2<br></br>max. 2MB<br></br>Dateityp: png, jpg, jpeg, gif</span>
            </dfn>
            <dfn className="tooltip" style={{display:this.state.paket!='comfort'?'none':'block'}}>Banner/Background Motiv
            <span role="tooltip" >1500x750px; 2:1<br></br>max. 2MB<br></br>Dateityp: png, jpg, jpeg, gif</span>
            </dfn>
            <dfn className="tooltip" style={{display:this.state.paket!='premium'?'none':'block'}}>Banner/Background Motiv
            <span role="tooltip" >2000x500px; 4:1<br></br>max. 2MB<br></br>Dateityp: png, jpg, jpeg, gif</span>
          </dfn>
          </div> 

              <div style={{width: '595px'}} className="file-upload">
                <div className="upload-text">Datei:</div>
                <input readOnly type="text" className="input-field" value={this.state.backgroundImage ? this.state.backgroundImage.name : ""}></input>
                <FileUploader image={true} handleFile={(f) => this.handleBackgroundUpload(f, "backgroundImage")}></FileUploader>              
              </div>
        </div>
            <div className="text-field">            
              <div className="text-field-headline">Website des Unternehmens</div>
              <input
                pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
                type="text"
                placeholder="Unternehmenswebsite"
                className="input-field-wobutton"
                value={this.state.companyWebsite}
                onChange={(e) => this.handleChange(e, 'companyWebsite')}                
              />
            </div>
            <div className="text-field">
              <div className="text-field-headline">Headline (Satz zum Unternehmen / Slogan)</div>
              <input
                pattern=".{1,255}"
                type="text"
                placeholder="Slogan"
                className="input-field-wobutton"
                value={this.state.companySlogan}
                onChange={(e) => this.handleChange(e, 'companySlogan')}
                />
            </div>
          </div>
        </div>
        <div className="large-text-field">
          <div className="text-field-headline">
          <dfn className="tooltip">Informationen zum Unternehmen
            <span role="tooltip">max. 1.000 Zeichen </span>
            </dfn>
          </div>
          <textarea
            pattern=".{1,1000}"
            rows="5"
            type="text"
            placeholder="Text über das Unternehmen"
            className="input-field-wobutton"
            value={this.state.companyInfo}
            onChange={(e) => this.handleChange(e, 'companyInfo')}            
          />
        </div>
        
        <div className="large-text-field">
          <div className="text-field-headline" style={{display:this.state.paket!='basic'?'none':'block'}}>5 Charts – Google slides (URL)</div>            
          <div className="text-field-headline" style={{display:this.state.paket!='basic'?'block':'none'}}>{this.state.paket=='comfort'?'10':'15'} Charts – Google slides (URL) oder Youtube, Vimeo oder Wistia URL</div>
          <input            
            type="text"
            pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            placeholder={this.state.paket=='basic'?'Google Slides URL':'Enter Google Slides URL or Video URL'}
            className="input-field-wobutton"
            value={this.state.googleSlidesURL}
            onChange={(e) => this.handleChange(e, 'googleSlidesURL')}            
          />
        </div>
        <div className="large-text-field">
          <div className="text-field-headline">Social Media URL (Twitter, Facebook, Instagram, LinkedIn)</div>          
          <input
            pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
            placeholder="Social Media URL"
            className="input-field-wobutton"            
            value={this.state.companySocialMediaURL1}
            onChange={(e) => this.handleChange(e, 'companySocialMediaURL1')}            
          />
          <div style={{padding:'10px', display:this.state.paket!='basic'?'block':'none'}}></div>
          <input
            pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
            placeholder="Social Media URL"
            className="input-field-wobutton"
            value={this.state.companySocialMediaURL2}
            style={{display:this.state.paket!='basic'?'block':'none'}}
            onChange={(e) => this.handleChange(e, 'companySocialMediaURL2')}            
          />
          <div style={{padding:'10px', display:this.state.paket!='premium'?'none':'block'}}></div>
          <input
            pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
            placeholder="Social Media URL"
            className="input-field-wobutton"
            style={{display:this.state.paket!='premium'?'none':'block'}}
            value={this.state.companySocialMediaURL3}
            onChange={(e) => this.handleChange(e, 'companySocialMediaURL3')}
          />
        </div>
        <div className="headline">
          <dfn className="tooltip"><b>Sessions:</b>
            <span role="tooltip">Bitte geben sie den Session Name, Datum sowie Uhrzeit und beteiligte Personen (Vor- und Nachname der Referenten analog Registrierung im Hopin) an. </span>
          </dfn>          
          <button type="button" style={{border:'0px', background:'none'}}><img src={PlusButton} style={{border:'0px', padding:'0px', width:'100%'}} onClick={this.addSession} /></button>
        </div>
          {this.state.sessions.map((session, index) => {
            return (                          
            <div className="column-container">
              <div className="column"> 
              <div className="text-field-headline">Sessionname:</div>
              <div className="layout-element">
                  <input className="contact-input"
                    type="text"
                    value={this.state.sessions[index].sessionname}
                    placeholder="Session Name"
                    className="input-field-wobutton"                    
                    onChange={(e) => this.handleSessionNameChange(e, index)}
                    required
                  />
                </div>
                <div className="text-field-headline">Beteiligte Personen:</div>
                <div className="layout-element">
                  <input className="contact-input"
                    type="text"
                    placeholder="Name"
                    className="input-field-wobutton"
                    value={this.state.sessions[index].person1}
                    onChange={(e) => this.handleSessionPersonChange(e.target, index,0)}
                    required
                  />
                </div>
                <div className="layout-element">
                  <input className="contact-input"
                    type="text"
                    placeholder="Name"
                    className="input-field-wobutton"
                    value={this.state.sessions[index].person3}
                    onChange={(e) => this.handleSessionPersonChange(e.target, index,2)}
                  />
                </div>
                <div className="layout-element">
                  <input className="contact-input"
                    type="text"
                    placeholder="Name"
                    className="input-field-wobutton"
                    value={this.state.sessions[index].person5}
                    onChange={(e) => this.handleSessionPersonChange(e.target, index,4)}                 
                  />
                </div>
              </div>            
              <div className="column"> 
              <div className="text-field-headline">Uhrzeit:</div>
                <div className="layout-element">
                  <DateTimePicker
                    min={new Date(2021, 2, 2)}
                    max={new Date(2021, 2, 3, 23, 59, 59)}
                    value={this.state.sessions[index].sessiondate}
                    onChange={(value ) => this.handleDateChange(value, index)}
                    //date={true}
                    //time={true}
                    defaultValue={new Date(2021, 2, 2,10,0,0)}                    
                  />          
                </div>
                <div className="layout-element" style={{height: '45px'}}/>
                <div className="layout-element">
                  <input className="contact-input"
                    type="text"
                    placeholder="Name"
                    className="input-field-wobutton"
                    value={this.state.sessions[index].person2}
                    onChange={(e) => this.handleSessionPersonChange(e.target, index,1)}               
                  />
                </div>
                <div className="layout-element">
                  <input className="contact-input"
                    type="text"
                    placeholder="Name"
                    className="input-field-wobutton"
                    value={this.state.sessions[index].personen4}
                    onChange={(e) => this.handleSessionPersonChange(e.target, index,3)}                    
                  />
                </div>
                <div className="layout-element">
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remSession(index)} /></button>
                </div>
              </div>
            </div>            
            );
          })}
        <div className="headline">
          <b>Produktpräsentation</b>
        </div>                    
        <div className="column-container">
          <div className="column">       
            <div>
            <p className="text-field-headline-2">
            <dfn className="tooltip">Produktbeschreibung
                <span role="tooltip">max. 1.000 Zeichen</span>
              </dfn></p>
            </div>
          </div><div className="column">                               
            <div>
              <p className="text-field-headline-2">
              <dfn className="tooltip">Produktbilder
                <span role="tooltip">Auflösung: 300 dpi, mind. 500 px breit<br></br>
                  Querformat oder 16:9<br></br>
                  max. 5MB<br></br>
                  Dateityp: jpeg, png, gif, bmp, tiff </span>
              </dfn></p>
            </div>
          </div>
        </div>
        <div className="column-container">
          <div className="column">          
            <div className="layout-element-2" style={{display:"block"}}>
              <div className="medium-text-field">                
                <textarea
                  pattern=".{0,1000}"
                  rows="5"
                  type="text"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo1}
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo1')}                  
                />
              </div>
            </div>
            <div className="layout-element-2" style={{display:"block"}}>
              <div className="medium-text-field">                
                <textarea
                  rows="5"
                  type="text"
                  pattern=".{0,1000}"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo2}
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo2')}                  
                />
              </div>
            </div>
            <div className="layout-element-2" style={{display:"block"}}>
              <div className="medium-text-field">                
                <textarea
                  rows="5"
                  type="text"
                  pattern=".{0,1000}"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo3}
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo3')}                  
                />
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="medium-text-field">                
                <textarea
                  rows="5"
                  type="text"
                  pattern=".{0,1000}"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo4}                  
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo4')}                  
                />
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="medium-text-field">
                <textarea
                  rows="5"
                  type="text"
                  pattern=".{0,1000}"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo5}                  
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo5')}                  
                />
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="medium-text-field">
                <textarea
                  rows="5"
                  type="text"
                  pattern=".{0,1000}"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo6}                  
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo6')}                  
                />
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="medium-text-field">
                <textarea
                  rows="5"
                  type="text"
                  pattern=".{0,1000}"
                  placeholder="Produktbeschreibung"
                  className="input-field-wobutton"
                  value={this.state.additionalCompanyInfo7}                  
                  onChange={(e) => this.handleChange(e, 'additionalCompanyInfo7')}                  
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="layout-element-2" style={{display: "block"}}>

            <div className="file-upload" >
                <div className="upload-text">Datei:</div>                
                <input disabled type="text" className="input-field" value={this.state.productImage1 ? this.state.productImage1.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage1")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage1", this.state.productImage1)} /></button>                             
              </div>
            </div>
            <div className="layout-element-2" style={{display: "block"}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.productImage2 ? this.state.productImage2.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage2")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage2")} /></button>                             
              </div>
            </div>
            <div className="layout-element-2" style={{display: "block"}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.productImage3 ? this.state.productImage3.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage3")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage3")} /></button>                             
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled  type="text" className="input-field" value={this.state.productImage4 ? this.state.productImage4.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage4")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage4")} /></button>                             
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled  type="text" className="input-field" value={this.state.productImage5 ? this.state.productImage5.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage5")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage5")} /></button>                             
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled  type="text" className="input-field" value={this.state.productImage6 ? this.state.productImage6.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage6")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage6")} /></button>                             
              </div>
            </div>
            <div className="layout-element-2" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled  type="text" className="input-field" value={this.state.productImage7 ? this.state.productImage7.name : ""}></input>
                <FileUploader image={true} thin={true} handleFile={(f) => this.handleProductImageUpload(f, "productImage7")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("productImage7")} /></button>                             
              </div>
            </div>            
          </div>
        </div>
        <div className="headline">
          <dfn className="tooltip">Downloaddatenbank
            <span role="tooltip">max. 5MB<br></br>
              Dateityp: pdf</span>
          </dfn>
        </div>
        <div className="column-container">
          <div className="column">
            <div className="layout-element">
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf1 ? this.state.downloadDbPdf1.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf1")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf1")} /></button>                
              </div>
            </div>
            <div className="layout-element">
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf3 ? this.state.downloadDbPdf3.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf3")}></FileUploader> 
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf3")} /></button>               
              </div>
            </div>
            <div className="layout-element">
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf5 ? this.state.downloadDbPdf5.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf5")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf5")} /></button>                
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf7 ? this.state.downloadDbPdf7.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf7")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf7")} /></button>                
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf9 ? this.state.downloadDbPdf9.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf9")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf9")} /></button>                
              </div>
            </div>
            <div className="layout-element"style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf11 ? this.state.downloadDbPdf11.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf11")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf11")} /></button>                
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf13 ? this.state.downloadDbPdf13.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf13")}></FileUploader>
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf13")} /></button>                
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf15 ? this.state.downloadDbPdf15.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf15")}></FileUploader> 
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf15")} /></button>               
              </div>
            </div>
          </div>
          <div className="column">
            <div className="layout-element">
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf2 ? this.state.downloadDbPdf2.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf2")}></FileUploader>              
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf2")} /></button>
              </div>
            </div>
            <div className="layout-element">
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf4 ? this.state.downloadDbPdf4.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf4")}></FileUploader> 
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf4")} /></button>            
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf6 ? this.state.downloadDbPdf6.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf6")}></FileUploader>   
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf6")} /></button>           
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf8 ? this.state.downloadDbPdf8.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf8")}></FileUploader>           
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf8")} /></button>   
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='basic'?'block':'none'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf10 ? this.state.downloadDbPdf10.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf10")}></FileUploader>         
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf10")} /></button>     
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf12 ? this.state.downloadDbPdf12.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf12")}></FileUploader>            
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf12")} /></button>  
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='premium'?'none':'block'}}>
              <div className="file-upload">
                <div className="upload-text">Datei:</div>
                <input disabled type="text" className="input-field" value={this.state.downloadDbPdf14 ? this.state.downloadDbPdf14.name : ""}></input>
                <FileUploader image={false} thin={true} handleFile={(f) => this.handlePdfFileUpload(f, "downloadDbPdf14")}></FileUploader>          
                <button type="button" style={{border:'0px', background:'none'}}><img src={DeleteButton} style={{border:'0px', height:'45px', width:'45px'}} onClick={()=>this.remFile("downloadDbPdf14")} /></button>    
              </div>
            </div>
            <div className="layout-element" style={{display:this.state.paket!='comfort'?'block':'none'}}></div>
          </div>
        </div>
        <div className="headline">          
          <dfn className="tooltip">Unternehmenstags
            <span role="tooltip">Die Tags gestalten sich analog der bereits erhaltenen Liste. Bei Eingabe der Wörter wird automatisch gefiltert.</span>
          </dfn>
        </div>
        <div className="column-container">
          <div className="column" style={{width:'80%'}}>
              <Multiselect
                dropUp
                value={this.state.tags}
                onChange={value => this.setState({ tags : value })}
                data={[
                  'Intec',
                  'Zuliefermesse',
                  'Additiv',
                  'Automatisierung',
                  'Automobilindustrie',
                  'Brennstoffzelle',
                  'Contact Businessmeetings',
                  'Dienstleistungen',
                  'Digitalisierung',
                  'Elektronik',
                  'Fertigungstechnik',
                  'Forschung',
                  'Halbzeuge',
                  'Handhabungstechnik',
                  'Hidden Champions',
                  'Hybrid',
                  'International Russland Polen Tschechien Rumänien',
                  'IT-Systeme',
                  'Komponenten',
                  'Kooperation',
                  'Kunststoffbearbeitung',
                  'Lasertechnik',
                  'Logistik',
                  'Maschinenbau',
                  'Messtechnik',
                  'Metallbearbeitung',
                  'Robotik',
                  'Sensorik',
                  'Smarte Produktion',
                  'Software',
                  'Steuerungstechnik',
                  'Technologien',
                  'Teile',
                  'Transformation',
                  'Wasserstoff',
                  'Werkstoffe',
                  'Werkzeuge',
                  'Werkzeugmaschinen',
                  'Zerspanung',
                  'Zulieferer'
                ]}                  
                />
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer">
          <div className="footer-text">
            Im Zusammenhang mit Ihrer Anmeldung verarbeitet die Leipziger Messe GmbH Ihre übermittelten Daten auf Grundlage des Art 6 Abs 1b der DSGVO.
            Weiter werden wir Ihre Daten unseren Dienstleistern zum Zwecke der Auftragserfüllung bereitstellen.
            Mit unseren Dienstleistern haben wir Auftragsverarbeitungsverträge nach Art. 28. Abs. 3 DSGVO geschlossen.
          </div>
          <div className="submit-form">
            <button className="submit-button">
              Versenden und Upload der Dateien
            </button>
            <button className="url" type="button" onClick={()=> window.open("mailto:intec-z-connect@fairnet.de")}>              
              Brauchen Sie Hilfe bei diesem Formular?
            </button>
          </div>
        </div>
      </form>
    );
  }
  }
}
//{()=> window.open("mailto:intec-z-connect@fairnet.de")}>
export default Baseform;
