import axios from "axios";
import ClientEndpoint from './ClientEndpoint';
import KeycloakAPI from './KeycloakAPI';

/*const _kc = new Keycloak({
    "realm": "225d8806", // TODO: Replace with name of configuration
    "auth-server-url": "https://keycloak.petricore.systems/auth",
    "ssl-required": "external",
    "resource": "exhibio",
    "public-client": true,
    "confidential-port": 0,
    "url": "https://keycloak.petricore.systems/auth",
    "clientId": "exhibio",
    //"enable-cors": true, // War mal an
});*/

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

var onConnectedObservers = [];

const SessionData = {
    clientInstance: null,
    currentUser: null,
    isLoggedIn: () => {
        return SessionData.clientInstance != null && SessionData.clientInstance.state.info != null
    },
    isLoggedOut: () => {
        return SessionData.clientInstance != null && SessionData.clientInstance.state.info == null
    },
    login: (user, password) => {
        return new Promise((resolve, reject) => {
            if (SessionData.isLoggedOut()) {
                SessionData.clientInstance.login(user, password).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                });
            } else {
                reject("Already logged in or no session available.");
            }
        })
    },
    getRoles: () => {
        if (SessionData.isLoggedIn()) {
            if (SessionData.clientInstance.state.info.realmroles != null) {
                return SessionData.clientInstance.state.info.realmroles;
            } else {
                return [];
            }
        }
    },
    doLogout: () => {
        return new Promise((resolve, reject) => {
            if (SessionData.isLoggedIn()) {
                SessionData.clientInstance.logout().then((res) => {
                    SessionData.clientInstance.state.info = null;
                    resolve()
                });
            } else {
                resolve()
            }
        })
    },

    getUsername: () => {
        if (SessionData.isLoggedIn()) {
            return SessionData.clientInstance.state.info.preferred_username;
        }
        return null;
    },

    getUserID: () => {
        if (SessionData.isLoggedIn()) {
            return SessionData.clientInstance.state.info.sub;
        }
        return "";
    },

    init: (realmHash) => {

        const self = this;
        
        return new Promise((resolve, reject) => {
            new ClientEndpoint.Endpoint(realmHash, 'exhibio', 'testuser', 'password')
            .then((res) => {
                SessionData.clientInstance = res;
                resolve(self);
            }).catch((res) => {
                SessionData.clientInstance = res;
                resolve(self);
            })
        })
    }
}

const AdminData = {
    adminInstance: null,
    isLoggedIn: () => {
        return AdminData.adminInstance != null && AdminData.adminInstance.state.auth != null
    },
    isLoggedOut: () => {
        return AdminData.adminInstance != null && AdminData.adminInstance.state.auth == null
    },
    login: () => {
        return new Promise((resolve, reject) => {
            // This seems to have caused the bug.
            //if (AdminData.isLoggedOut()) {
                AdminData.adminInstance.login().then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                });
            //} else {
            //    resolve("Already admin-logged in or no session available.");
            //}
        })
    },
    init: (realm, callback) => {
        new ClientEndpoint.Endpoint(realm, 'admin-cli', 'c25a2153-c96b-48ee-98cb-6012b3223223')
            .then((res) => {
                AdminData.adminInstance = res;
                callback();
            })
    },
    getCurrentUser: (overwrite) => {
        if (SessionData.currentUser != null && !overwrite) {
            return new Promise((resolve, reject) => { resolve(SessionData.currentUser) })
        }
        return new Promise((resolve, reject) => {
            AdminData.getUserData(SessionData.clientInstance.state.info.sub, function (user) {
                SessionData.currentUser = user;
                if (SessionData.currentUser.attributes == null){
                    SessionData.currentUser.attributes = {}
                }
                if (SessionData.currentUser.groups == null){
                    SessionData.currentUser.groups = []
                }
                resolve(user);
            })
        })
    },
    getAttribute: (data, key) => {
        return data.attributes[key]
    },
    setAttribute: (key, value) => {
        AdminData.getCurrentUser().then((user) => {
            user.attributes[key] = value
        })
    },
    addToGroup: (rolename) => {
        AdminData.getCurrentUser().then((user) => {
            user.groups.push(rolename);
        })
    },
    addToAttribute: (key, value) => {
        AdminData.getCurrentUser().then((user) => {
            user.attributes[key].push(value);
        })
    },
    removeFromAttribute: (key, value) => {
        AdminData.getCurrentUser().then((user) => {
            user.attributes[key].splice(user.attributes[key].indexOf(value), 1);
        })
    },
    getUserData: (user, callback) => {
        AdminData.login().then (() => {
            KeycloakAPI.ToAdminConsole(
                AdminData.adminInstance.state.realm,
                ClientEndpoint.EP_ADMIN.users + "/" + user,
                'GET',
                [], {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                }
            ).then(function (response) {
                if (response.length != null && response.length > 0) {
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].attributes == null || response[i].attributes.length == 0) {
                            response[i].attributes = {
                                scenebundles: [
                                    /*'2d500d42aa1144088c6603f5758fa1bc',
                                    '2d400d42aa1144088c6603f5758fa1bc',
                                    '2d300d42aa1144088c6603f5758fa1bc',
                                    '2d200d42aa1144088c6603f5758fa1bc',
                                    '2d100d42aa1144088c6603f5758fa1bc',*/
                                ]
                            }
                        }
                    }
                } else {
                    if (response.attributes == null || response.attributes.length == 0) {
                        response.attributes = {
                            scenebundles: [
                                /*'2d500d42aa1144088c6603f5758fa1bc',
                                '2d400d42aa1144088c6603f5758fa1bc',
                                '2d300d42aa1144088c6603f5758fa1bc',
                                '2d200d42aa1144088c6603f5758fa1bc',
                                '2d100d42aa1144088c6603f5758fa1bc',*/
                            ]
                        }
                    }
                }
                callback(response);
            }).catch((e) => {
                console.error(e);
            })
        }).catch((res) => {
            console.error(res);
        })
    },
    resetUserPassword: (user, password, callback) => {
        AdminData.login().then (() => {
            axios
                .put(`${KeycloakAPI.rootURL}/auth/admin/realms/${AdminData.adminInstance.state.realm}/users/` + user + "/reset-password",
                    {
                        value: password,
                        type: "password",
                        temporary: false,
                    }
                    , {
                        headers: {
                            //'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                        }
                    }
            ).then(function (response) {
                callback(response.success);
            }).catch((e) => {
                console.error(e);
            })
        }).catch((res) => {
            console.error(res);
        })
    },
    postUserData: (user, id) => {
        console.log("POSTING USER DATA")
        var usedID = id;
        if (id == null) {
            usedID = SessionData.clientInstance.state.info.sub
        }
        AdminData.login().then (() => {
            console.log("ADMIN LOGGED IN")
            console.log(SessionData.clientInstance.state)
            axios
                .put(`${KeycloakAPI.rootURL}/auth/admin/realms/${AdminData.adminInstance.state.realm}/users/` + id,
                    user
                    , {
                        headers: {
                            'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                        }
                    }).then(() => {
                        if (id == null) {
                            AdminData.getCurrentUser(true).then((a) => {
                                console.log("FINISHED POSTING USER DATA")
                            })
                        }
                    })
                })
    },
    createNewUser: (username, password, email, enabled) => {
        return new Promise((resolve, reject) => {

            if (enabled == null) {
                enabled = true;
            }
            
            const user = {
                credentials: [
                    {
                        type: "password",
                        value: password
                    }
                ],
                username: username,
                email: email,
                enabled: enabled,
            }
    
            AdminData.login().then (() => {
                axios
                    .post(`${KeycloakAPI.rootURL}/auth/admin/realms/${AdminData.adminInstance.state.realm}/users`,
                        user
                        , {
                            headers: {
                                'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                            }
                        }).then(() => {
                            console.log("FINISHED CREATING NEW USER")
                            SessionData.login(username, password).then((res) => {
                                SessionData.clientInstance.getInfo();
                                console.log("RESOLVING")
                                resolve(AdminData);
                            })
                        }).catch((err) => {
                            console.log(err)
                        })
                    })
        })
    },
  subscribe: (f) => {
    onConnectedObservers.push(f);
  }
}

export default {
    SessionData,
    AdminData,
}