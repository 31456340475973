import KeycloakAPI from './KeycloakAPI';
import { instanceOf } from "prop-types";

const EP_CLIENT = {
    REGISTRATION: "clients-registrations/openid-connect",
    TOKEN: "protocol/openid-connect/token",
    AUTH: "protocol/openid-connect/auth",
    INTROSPECT: "protocol/openid-connect/token/introspect",
    USERINFO: "protocol/openid-connect/userinfo",
    LOGOUT: "protocol/openid-connect/logout",
    CERTS: "protocol/openid-connect/certs",
    LOGINIFRAME: "protocol/openid-connect/login-status-iframe.html",
}

const EP_ADMIN = {
    users: "users",
}

class ClientEndpoint {
    state = {
        realm: null,
        client: null,
        reauthenticateLoop: null,
        refreshToken: "",
        auth: null, // authentication callback from login procedure
        info: null, // username, roles, etc...
        secret: null,
    };

    constructor(realm, client, clientSecret) {
        const self = this;
        this.state.client = client;
        this.state.realm = "live_" + realm;

        this.state.secret = clientSecret

        return new Promise(
            function (resolve, reject) {
                if (client != 'admin-cli') {
                    if (self.state.refreshToken != "") {
                        self.reauthenticateToken(self).then((res) => {
                            self.getInfo().then((r) => {
                                resolve(self);
                            }).catch((err) => {
                                reject(err)
                            })
                        }).catch((err) => {
                            reject(err)
                        })
                    } else {
                        resolve(self);
                    }
                } else {
                    self.login(clientSecret).then(() => {
                        resolve(self);
                    }).catch((res) => {
                        reject(res);
                    })
                }
            }
        )
    }

    getInfo() {
        const self = this
        return new Promise((resolve, reject) => {
            KeycloakAPI.ToRealm(
                self.state.realm,
                EP_CLIENT.USERINFO,
                'GET',
                null,
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + self.state.auth.access_token
                }
            ).then((res) => {
                self.state.info = res
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    login(username, password) {
        const self = this;
        if (this.state.reauthenticateLoop != null) {
            clearInterval(this.state.reauthenticateLoop)
        }
        return new Promise(
            function (resolve, reject) {
                if (password == null) {
                    if (username == null) {
                        username = self.state.secret;
                    }
                    KeycloakAPI.ToRealm(
                        'master',
                        EP_CLIENT.TOKEN,
                        'POST',
                        [
                            ['grant_type', 'client_credentials'],
                            ['client_id', 'admin-cli'],
                            ['client_secret', username],
                        ]
                    ).then((res) => {
                        self.state.auth = res;
                        self.state.reauthenticateLoop = setInterval(() => self.reauthenticateToken.bind(self), (self.state.auth.expires_in - 20) * 1000)
                        resolve(self);
                    }).catch((res) => {
                        reject(res);
                    })
                } else {
                    KeycloakAPI.ToRealm(
                        self.state.realm,
                        EP_CLIENT.TOKEN,
                        'POST',
                        [
                            ['grant_type', 'password'],
                            ['client_id', self.state.client],
                            ['username', username],
                            ['password', password],
                        ]
                    ).then((res) => {
                        self.state.auth = res;
                        self.state.refreshToken = res.refresh_token;
                        self.state.reauthenticateLoop = setInterval(() => self.reauthenticateToken.bind(self), (self.state.auth.expires_in - 20) * 1000)
                        self.getInfo().then((r) => {
                            resolve(self);
                        }).catch((err) => {
                            reject(err)
                        })
                    }).catch((res) => {
                        reject(res);
                    })
                }
            }
        )
    }

    logout() {
        return new Promise((resolve, reject) => {
            if (this.state.auth != null) {
                const self = this;
                clearInterval(this.state.reauthenticateLoop)
                KeycloakAPI.ToRealm(
                    this.state.realm,
                    EP_CLIENT.LOGOUT,
                    'POST',
                    [
                        ['client_id', this.state.client],
                        ['refresh_token', this.state.auth.refresh_token],
                    ], {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    //'Authorization': 'Bearer ' + this.state.auth.access_token
                }
                ).then((res) => {
                    self.state.refreshToken = '';
                    resolve()
                }).
                    catch((res) => {
                        console.log("LOGOUT ERROR")
                        console.error(res);
                        resolve()
                    })
            } else {
                resolve()
            }
        })
    }

    reauthenticateToken(self) {
        return new Promise((resolve, reject) => {
            KeycloakAPI.ToRealm(
                self.state.realm,
                EP_CLIENT.TOKEN,
                'POST',
                [
                    ['client_id', self.state.client],
                    ['refresh_token', self.state.refreshToken],
                    ['grant_type', 'refresh_token'],
                ], {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            ).then((res) => {
                self.state.auth = res;
                self.state.refreshToken = self.state.auth.refresh_token;
                resolve(self);
            }).catch((res) => {
                if (res.status == 400) {
                    if (self.state.client != 'admin-cli') {
                        self.logout();
                        console.log("LOGGED OUT")
                        reject(self);
                    } else {
                        self.login(self.state.secret).then((r) => {
                            resolve(self)
                            console.log("ADMIN LOGGING BACK IN")
                        }).catch((err) => {
                            reject(self)
                            console.log("ADMIN FAILED LOGGING BACK IN")
                            console.log(err)
                        })
                    }
                }
            })
        })
    }
}

export default {
    Endpoint: ClientEndpoint,
    EP_ADMIN: EP_ADMIN,
    EP_CLIENT: EP_CLIENT,
}