import React from 'react';
import UserService from "../Keycloak/UserService";

class LoginForm extends React.Component {
    static defaultProps = {
        buttonText: 'Start the Experience',
    };

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            passconf: '',
            passwordIsReset: false,
            passwordReset: false,
            failedCheck: false
        };
        this.switchToPasswordResetForm = this.switchToPasswordResetForm.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    handleChange(event, parameter) {
        event.persist();
        this.setState({
            [parameter]: event.target.value,
        });
    }

    switchToPasswordResetForm() {
        this.setState({
            passwordReset: true,
            failedCheck: false,
        });
    }

    resetPassword() {
        const self = this;
        this.setState({
            failedCheck: false,
        });
        UserService.AdminData.getUserData("", function(users) {
            var email = ""
            for (var i=0; i < users.length; i++) {
                console.log(users[i].email);
                console.log(self.state.username);
                if (users[i].email == self.state.username) {
                    email = users[i].email;
                    users[i].requiredActions = ["UPDATE_PASSWORD", "VERIFY_EMAIL"]
                    console.log("Updating User");
                    UserService.AdminData.postUserData(users[i], users[i].id);
                }
            }
            if (email != "") {
                self.setState({
                    passwordIsReset: true,
                });
            } else {
                self.setState({
                    failedCheck: true,
                });
            }
        })

    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.loggedIn) {
            if (this.props.needsActivation) {
                const password = this.state.password;
                const passconf = this.state.passconf;
                if (password == passconf) {
                    this.setState({failedCheck: false})
                    this.props.onLogin(null, password).catch((err) => {
                        this.setState({failedCheck: true})
                    })
                } else {
                    this.setState({failedCheck: true})
                }
            } else {
                this.loggedIn = true;
                const username = this.state.username;
                const password = this.state.password;
    
                this.setState({ show: false });
                this.props.onLogin(username, password).then((res) => {
                    this.props.onStart(username);
                }).catch((err) => {
                    this.setState({failedCheck: true})
                    this.loggedIn = false
                    console.log(err)
                });
            }
        }
    }

    render() {
        if (this.props.loading) {
            return (<div/>)
        }
        else if (this.state.passwordReset) {
            return (<div className="login">
                <label>
                    <b>E-Mail</b>
                </label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    required={!this.props.needsActivation}
                    placeholder="Geben Sie Ihre E-Mail Adresse ein."
                    value={this.state.username}
                    onChange={(e) => this.handleChange(e, 'username')}
                />
                <button onClick={this.resetPassword} className="btn-primary btn-primary--inverted">
                    Passwort zurücksetzen
                </button>
                
                <div style={{display: this.state.passwordIsReset ? 'block' : 'none'}}>
                    Passwort erfolgreich zurückgesetzt. Bitte überprüfen Sie Ihren E-Mail Posteingang.
                </div>
            </div>)
        }
        return (
            <div>
                <form className="login" onSubmit={this.handleSubmit.bind(this)}>
                    <div style={{display: this.props.needsActivation ? "block" : "none"}}>
                        <label>
                            <b>Geben Sie ein Passwort ein um Ihre Registrierung fertigzustellen.</b>
                        </label>
                    </div>
                    <div style={{display: !this.props.needsActivation ? "block" : "none"}}>
                        <label htmlFor="username">
                            <b>Nutzername</b>
                        </label>
                        <input
                            type="text"
                            id="username"
                            autocomplete="on"
                            name="username"
                            required={!this.props.needsActivation}
                            placeholder="Nutzername eingeben und loslegen"
                            value={this.state.username}
                            onChange={(e) => this.handleChange(e, 'username')}
                        />
                    </div>
                    <label htmlFor="password">
                        <b>Passwort</b>
                    </label>
                    <input
                        type="password"
                        id="password"
                        autocomplete="off"
                        name="password"
                        required
                        placeholder="Passwort eingeben"
                        value={this.state.password}
                        onChange={(e) => this.handleChange(e, 'password')}
                    />
                    <div style={{display: this.props.needsActivation ? "block" : "none"}}>
                        <label htmlFor="password">
                            <b>Neues Passwort bestätigen</b>
                        </label>
                        <input
                            type="password"
                            id="passconf"
                            name="passconf"
                            autocomplete="off"
                            required={this.props.needsActivation}
                            placeholder="Neues Passwort erneut eingeben"
                            value={this.state.passconf}
                            onChange={(e) => this.handleChange(e, 'passconf')}
                        />
                    </div>
                    
                    <div style={{display: this.state.failedCheck ? 'block' : 'none'}}>
                        <div style={{foreground: "#ff0000ff"}}>
                            Login fehlgeschlagen. Möglicherweise war die Eingabe falsch oder der Account ist deaktiviert.
                        </div>
                    </div>

                    <button type="submit" className="btn-primary btn-primary--inverted">
                        Login
                    </button>
                </form>
                <div style={{display: !this.props.needsActivation ? "block" : "none"}}>
                    <button className="btn-subtle" onClick={this.switchToPasswordResetForm}>
                            Passwort vergessen? Klicken Sie hier um es zurückzusetzen.
                    </button>
                </div>
            </div>
        );
    }
}

export default LoginForm;
