import React from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import UncheckedButton from '../../Unchecked.png';
import CheckedButton from '../../Checked.png';
import axios from 'axios';




class Board extends React.Component 
{  
    getInitialInformation()
    {              
        this.addChange("Testeintrag", "Firmeninformationen; Produkte");
        this.addChange("Testeintrag2","Downloaddatenbank");
        this.addChange("Testeintrag3", "Sessions; Tags");        
        const self = this;
    let url = 'https://upload.messe-intec.de/dashboard/dashboardapi.php?';
    //let url = 'http://cdn.exhib.io/test2/dashboard/dashboardapi.php?';
    
      axios.get(url+'&task=changes',
      {

      })
        .then(function (response) {  
            console.log(response);
            let changes = response.data['changes'];
            for( let i = 0; i < changes.length; i++)
            {
                var changeItem = {
                    changename: changes[i].name,
                    changes:changes[i].change,
                    fnapproved: false,
                    komapproved: false
                };
                self.state.changes.push(changeItem);
            }
            self.forceUpdate();
    })
    .catch(function (response) {
        console.log(response);
    });
    
    }

    addChange(name, changes)
    {
        var change = {
            changename: name,
            changes: changes,
            fnapproved: false,
            komapproved: false
        };
        this.state.changes.push(change);
    }

    verifyChange()
    {

    }
    
  emptyfunction()
  {}  

  toggleFnApproved(index)
  {
      this.state.changes[index].fnapproved = !this.state.changes[index].fnapproved;
      this.forceUpdate();
  }

  toggleKomApproved(index)
  {
      this.state.changes[index].komapproved = !this.state.changes[index].komapproved;
      this.forceUpdate();
  }

  constructor(props) {
    super(props);    
    this.state = {
      changes: [],
      synced: false,
      
      failedToVerifyPackage: false,
    };
    
    const self = this;
    
  }

  render() {
    if (!this.synced)
    {
        this.synced=true;
        this.getInitialInformation();
    }
    return (
    <div className="App-content" style={{minHeight: '60vh'}}>
        <div className="headlineupload" style={{height: '45px'}}>
                    <input readOnly type="Text" style={{width:"20%", border:"0px", background:"#FFFFFF" }} className="input-field-wobutton" value="Firma"></input>
                    <input readOnly type="Text" style={{width:"40%", border:"0px", background:"#FFFFFF"}} className="input-field-wobutton" value="Änderungen"></input>
                    <input readOnly type="Text" style={{width:"55px", border:"0px", background:"#FFFFFF", textAlign:"center"}} className="input-field-wobutton" value="FN"></input>
                    <input readOnly type="Text" style={{width:"55px", border:"0px", background:"#FFFFFF", textAlign:"center"}} className="input-field-wobutton" value="KOM"></input>                    
                </div>
        {this.state.changes.map((state, index) => {
            return (                          
                <div className="headlineupload" style={{height: '45px'}}>
                    <input readOnly type="Text" style={{width:"20%"}} className="input-field-wobutton" value={this.state.changes[index].changename}></input>                    
                    <input readOnly type="Text" style={{width:"40%"}} className="input-field-wobutton" value={this.state.changes[index].changes}></input>                    
                    <button type="button" style={{border:'0px', background:'none'}}><img src={this.state.changes[index].fnapproved? CheckedButton : UncheckedButton} style={{verticalAlign: 'middle', paddingBottom: "12px", border:'0px', height:'45px', width:'45px'}} onClick={()=>this.toggleFnApproved(index)} /></button>
                    <button type="button" style={{border:'0px', background:'none'}}><img src={this.state.changes[index].komapproved? CheckedButton : UncheckedButton} style={{verticalAlign: 'middle', paddingBottom: "12px",border:'0px', height:'45px', width:'45px'}} onClick={()=>this.toggleKomApproved(index)} /></button>                    
                </div>
            );
        })}        
    </div>)
  }
}
export default Board;
