import React from 'react';
import logo1 from '../../IUZc_blue.jpg'
import logo2 from '../../LM_Logo_color_dt.jpg'
import UserService from './Keycloak/UserService.js' 
class Header extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      paket: ''
    };
    const self = this;    
    UserService.AdminData.getCurrentUser().then((user) => {
      const paket = UserService.AdminData.getAttribute(user, 'paket');
      if (paket != null) {
        self.state.paket = paket[0].toLowerCase();
      }  
      this.forceUpdate() 
    })
  }

  render() {
    return (
      <div className="App-header">
        <div className="logo-container">
          <img className="logoIntecT" src={logo1}>

          </img>
          <img className="logoLeipzigerMesse" src={logo2}>

          </img>
        </div>

        <div className="divider"></div>
        <div className="App-intro">
          Dashboard Uploads für Intec Z Connect
        </div>
      </div>
    );//Einreichung Datenpaket ({this.state.paket.charAt(0).toUpperCase() + this.state.paket.slice(1)}) in Intec Z Connect
  }
}
export default Header;
