import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Baseform from './base/components/baseform.js'
import Header from './base/components/header.js'
import Board from './base/components/board.js'
import LoginForm from './base/components/LoginForm/LoginForm.js'
import logo2 from './LM_Logo_color_dt.jpg'
import UserService from './base/components/Keycloak/UserService.js'
class App extends React.Component {

  userID = null;

  constructor(props) {
    super(props)
    const urlParams = new URLSearchParams(window.location.search);
    this.userID = urlParams.get('id');

    this.state = {
      loggedIn: false,
      needsActivation: true,
      loadingAccountStatus: true,
    }
    const self = this;
    UserService.SessionData.init("64d9ba31")
    UserService.AdminData.init("64d9ba31", function() {
      if (self.userID) {
        UserService.AdminData.getUserData(self.userID, function(res) {
          if (res.requiredActions.indexOf("UPDATE_PASSWORD") == -1) {
            self.setState({needsActivation: false});
          }
          self.setState({loadingAccountStatus: false});
        })
      } else {
        self.setState({loadingAccountStatus: false, needsActivation: false});
      }
    }
    )
  }

  start = () => {
      this.setState({ loggedIn: true });
  };

  handleLogin = (username, password) => {
    const self = this;
    if (this.state.needsActivation) {
      return new Promise((resolve, reject) => 
      UserService.AdminData.resetUserPassword(this.userID, password, function(res) {
        self.setState({needsActivation: false});
        resolve();
      }))
    } else {
      this.setState({ username: username });
      return new Promise((resolve, reject) =>
          UserService.SessionData.login(username, password)
              .then((res) => {
                  resolve(res);
              }).catch((err) =>
                  reject(err)
              )
      );
    }
  }

  render() {
    if (this.state.loggedIn) 
    {
      return (
      <div className="App">
        <Header />
        <Board />
      </div>
      );
    }
    
    else if (this.state.loggedIn) {
      return (
        <div className="App">
          <Header />
          <Baseform />
        </div>
      );

    } else {
      return (
        <div style={{height: "100vh"}}>
          <div className="start-screen">
            <div className="container">
              <div className="container__header">
                <img className="logoLeipzigerMesse" src={logo2}>

                </img>
              </div>

              <div className="container__body">
                <LoginForm
                  onStart={this.start}
                  buttonText={this.props.buttonText}
                  onLogin={this.handleLogin}
                  needsActivation = {this.state.needsActivation}
                  loading = {this.state.loadingAccountStatus}
                >

                </LoginForm>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default App;
